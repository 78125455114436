<template>
  <v-container fluid class="pa-0">
    <v-sheet color="secondary lighten-1 py-12" dark>
      <v-slide-y-transition>
        <v-row v-show="nextSession.start">
          <v-col cols="12" class="text-center">
            <h4 class="text-h4 pb-10 pulse">
              <v-icon large>mdi-information-outline</v-icon> {{ displayAlert }}
            </h4>
          </v-col>
        </v-row>
      </v-slide-y-transition>
      <v-row justify="center">
        <v-col cols="3" class="text-center">
          <v-icon class="pa-3" x-large>mdi-file-document</v-icon>
          <h4 class="text-h4 pa-3 font-weight-bold">{{ myForms }}</h4>
          <h5 class="text-h6 font-weight-light">
            Formulaires remplis<br />
            par mon officine
          </h5>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-icon class="pa-3" x-large
            >mdi-file-document-multiple-outline
          </v-icon>
          <h4 class="text-h4 pa-3 font-weight-bold">{{ allForms }}</h4>
          <h5 class="text-h6 font-weight-light">
            Formulaires remplis <br />par l'ensemble de la région
          </h5>
        </v-col>
        <v-col cols="3" class="text-center">
          <v-icon class="pa-3" x-large>mdi-calendar-clock </v-icon>
          <h4 class="text-h4 pa-3 font-weight-bold">{{ sessions }}</h4>
          <h5 class="text-h6 font-weight-light">
            Sessions auxquelles <br />mon officine a participé
          </h5>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import FormulaireService from "../../../services/formulaire.service"
import UserService from "../../../services/user.service"

export default {
  name: "BannerSection",
  data() {
    return {
      allForms: 0,
      sessions: 0,
      myForms: 0,
      nextSession: { start: null, end: null, period: null }
    }
  },
  mounted() {
    this.getAllForms()
    this.countSessions()
    this.getMyForms()
    this.getSessionDates()
  },
  computed: {
    displayAlert() {
      const now = new Date()
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      const nextSessionStart = new Date(this.nextSession.start)
      const nextSessionEnd = new Date(this.nextSession.end)
      const nextSessionPeriod = this.nextSession.period

      // Session in progress
      if (nextSessionStart < now && nextSessionEnd > now) {
        return "Session en cours"
      }
      // Session today
      else if (this.isSameDay(nextSessionStart, now)) {
        return "Session aujourd'hui " + nextSessionPeriod
      }
      // Session tomorrow
      else if (this.isSameDay(nextSessionStart, tomorrow)) {
        return "Prochaine session demain " + nextSessionPeriod
      }
      // Next session information
      else {
        return (
          "Prochaine session le " +
          nextSessionStart.toLocaleDateString("fr-FR") +
          " " +
          nextSessionPeriod
        )
      }
    }
  },
  methods: {
    getAllForms() {
      // Comptage des formulaires
      FormulaireService.countPatientsInfo().then(
        response => {
          this.allForms = response.data.patientsNumber
        },
        error => {}
      )
    },
    countSessions() {
      // Comptage des utilsateurs
      FormulaireService.countSessionByFiness().then(
        response => {
          this.sessions = response.data.countSession
        },
        error => {}
      )
    },
    getMyForms() {
      // Comptage des formulaires par finess
      FormulaireService.countPatientsInfoByFiness().then(
        response => {
          this.myForms = response.data.patientsNumberFiness
        },
        error => {}
      )
    },
    getSessionDates() {
      // Récupération des dates de sessions
      FormulaireService.getSessionDates()
        .then(response => {
          this.nextSession = this.getNextSession(response.data.sessionDates)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getNextSession(sessionDates) {
      // Récupération de la prochaine session
      let deltaArr = []
      for (let date of sessionDates) {
        deltaArr.push(new Date(date.end) - Date.now())
      }
      let timeUntilNextSession = Math.min(
        ...deltaArr.filter(x => {
          return x > 0
        })
      )
      return sessionDates[deltaArr.indexOf(timeUntilNextSession)]
    },
    isSameDay(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      )
    }
  }
}
</script>

<style scoped>
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  10% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  20% {
    transform: scale3d(1, 1, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
  animation-delay: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 3;
}
</style>
